import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, ValidatorFn } from '@angular/forms';
import { NzInputDirective } from 'ng-zorro-antd/input';

enum PasswordError {
  MinLength = 'at least 10 characters',
  Lowercase = 'a lowercase letter',
  Uppercase = 'an uppercase letter',
  Number = 'a number(ex. 0 - 9)',
  Special = 'a special character'
}

const PasswordRegRuleMap: Record<string, any> = {
  gpIsLowercaseLetter: [PasswordError.Lowercase, /[a-z]/],
  gpIsUppercaseLetter: [PasswordError.Uppercase, /[A-Z]/],
  gpIsNumber: [PasswordError.Number, /\d/],
  gpIsSpecialCharacter: [
    PasswordError.Special,
    // eslint-disable-next-line no-useless-escape
    /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·！￥…（）《》？：“”【】、；‘，。]/
  ]
};

const PasswordNumberRuleMap: Record<string, any> = {
  gpPasswordLengthMinimum: (d: number) => [`at least ${d} characters`, new RegExp(`^.{${d},}$`)]
};

@Component({
  selector: 'stream-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.less']
})
export class PasswordInputComponent implements OnInit, ControlValueAccessor {
  constructor(
    @Optional()
    @Self()
    public ngControl: NgControl
  ) {
    if (this.ngControl !== null) {
      ngControl.valueAccessor = this;
    }
  }

  @Input()
  inputClass: string | string[] = '';

  @Input()
  nzSize: NzInputDirective['nzSize'] = 'default';

  @Input()
  suffix: TemplateRef<any> | string = ''; // eslint-disable-line

  @Input()
  ruleConfig!: Record<string, any>;

  disabled = false;

  $value = '';

  passwordVisible = false;

  _passwordValidators: [PasswordError, RegExp][] = [
    [PasswordError.MinLength, /^.{10,}$/],
    [PasswordError.Lowercase, /[a-z]/],
    [PasswordError.Uppercase, /[A-Z]/],
    [PasswordError.Number, /\d/],
    [
      PasswordError.Special,
      // eslint-disable-next-line no-useless-escape
      /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·！￥…（）《》？：“”【】、；‘，。]/
    ]
  ];

  @Input()
  errorStatus = false;

  @ViewChild('errorList')
  errorList!: TemplateRef<any>; // eslint-disable-line

  @Output()
  focus = new EventEmitter(); // eslint-disable-line

  @Output()
  blur = new EventEmitter(); // eslint-disable-line

  @Input()
  placeholder = 'Enter password';

  onTouch: () => unknown = () => null;

  passwordOnChange: (value: string) => void = (_value: string) => null;

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  get value() {
    return this.$value;
  }

  set value(value: string) {
    this.$value = value;
    this.passwordOnChange(this.$value);
  }

  get passwordValidators() {
    if (!this.ruleConfig) {
      return this._passwordValidators;
    }
    return this.getDynamicValidators();
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.passwordOnChange = fn;
  }

  ngOnInit(): void {
    this.ngControl.control?.setValidators(
      this.passwordValidators.map(([err, reg]) => this.passwordValidator(err, reg))
    );
    this.ngControl.control?.updateValueAndValidity();
  }

  passwordValidator(err: PasswordError, reg: RegExp): ValidatorFn {
    return control => (reg.test(control.value) ? null : { [err]: true });
  }

  getDynamicValidators() {
    return Object.entries(this.ruleConfig).reduce((acc, [key, value]) => {
      if (key in PasswordRegRuleMap && !!value) {
        acc.push(PasswordRegRuleMap[key]);
      } else if (key in PasswordNumberRuleMap) {
        acc.push(PasswordNumberRuleMap[key](value));
      }
      return acc;
    }, [] as [PasswordError, RegExp][]);
  }
}
